import React from "react"
import IndexPage from './index.js'

const ElectionsEVotingSystemPage = () => (
  <IndexPage
    title="Create Elections with nVotes' e-voting system"
    description="Grant legitimacy to your election with our auditable e-voting system"
    showCallToAction={true}
  />
)

export default ElectionsEVotingSystemPage

